import React from 'react';
import "./Team.scss";
import ProductDescription from '../../components/productDescription/ProductDescription';
import Team_Component from '../../components/team_componenets/Team_Component';
const descriptionText = "We're passionate about helping you achieve your wellness goals with cutting-edge technology and personalized support.";

const Team = () => {
  return (
    <div>
              <ProductDescription description={descriptionText} />      
              <Team_Component/>
    </div>
  )
}

export default Team
