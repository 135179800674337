import React, { useState, useCallback } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useWheel } from '@use-gesture/react';
import "./Mobile_Productslider.scss";

import GIF1 from "../../assets/GIF 1.gif";
import GIF2 from "../../assets/GIF 2.gif";
import GIF3 from "../../assets/GIF 3.gif";

const mobileSlides = [
    { id: 1, image: GIF1 },
    {
        id: 2,
        title: 'Calorie Analyzer-',
        line1: "Click - Upload -",
        line2: "Repeat",
        description: "Experience photo calorie counting for your meals and engage in 24/7 text conversations about gym and fitness. With VIP chat access for subscribers and responses powered by GPT-3, you're guaranteed top-notch interaction. Join the fitness conversation today and unlock advanced calorie counting features to elevate your health journey. This service ensures you stay informed and supported.",
    },
    { id: 3, image: GIF2 },
    {
        id: 4,
        title: 'Fitness AI Chatbot ',
        line1: "Available 24x7",
        description: "Exclusive text chat for subscribers is now available, featuring the user-friendly model GPT-4. Get free text-based gym and fitness advice and engage in text-only fitness chats. Activate your chat feature with your subscription, and enjoy a personalized experience that remembers your interactions, enhancing every conversation with tailored guidance.",
    },
    { id: 5, image: GIF3 },
    {
        id: 6,
        title: 'Form Check?',
        line1: "Upload it - Receive",
        line2: "Recommendations",
        description: "Utilize video form check with advanced ML models and snap meal photos for accurate calorie counting. We're upgrading to offer the ultimate chatbot experience, enhancing your ability to engage in text conversations about gym and fitness. Access specialized, text-based fitness chats with your subscription, designed to optimize your fitness journey and keep you motivated with state-of-the-art technology.",
    }
];

const Mobile_Productslider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = useCallback(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % mobileSlides.length);
    }, [mobileSlides.length]);

    const prevSlide = useCallback(() => {
        setCurrentSlide((prevSlide) => prevSlide === 0 ? mobileSlides.length - 1 : prevSlide - 1);
    }, [mobileSlides.length]);

    const handlers = useSwipeable({
        onSwipedLeft: nextSlide,
        onSwipedRight: prevSlide,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    // Debounce function to limit the rate of slide changes
    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    };

    const handleWheel = debounce(({ direction: [x] }) => {
        if (x > 0) nextSlide();
        if (x < 0) prevSlide();
    }, 150); // Adjust the wait time to control the speed

    const bind = useWheel(handleWheel);

    return (
        <div
            className='mobile_product_slider'
            {...handlers}
            {...bind()}
        >
            <div className='container_mobile_slider'>
                {mobileSlides[currentSlide].image && (
                    <div className='product_image'>
                        <img src={mobileSlides[currentSlide].image} alt={`Slide ${currentSlide + 1}`} className='product-gif' />
                    </div>
                )}
                {mobileSlides[currentSlide].title && (
                    <div className='product_details'>
                        <span className='product_title'>
                            {mobileSlides[currentSlide].title}<br />
                            {mobileSlides[currentSlide].line1}<br />
                            {mobileSlides[currentSlide].line2 ? mobileSlides[currentSlide].line2 : null}
                        </span>                       
                         <span className='product_info'>
                            {mobileSlides[currentSlide].description}
                        </span>
                    </div>
                )}
            </div>

            {mobileSlides.length > 1 && (
                <div className='indicators'>
                    {mobileSlides.map((_, index) => (
                        <button
                            key={index}
                            className={`indicator-buttons ${currentSlide === index ? 'active' : ''}`}
                            onClick={() => setCurrentSlide(index)}
                            aria-label={`Go to slide ${index + 1}`}
                        />
                    ))}
                </div>
            )}

            {/* <button className='prev-button' onClick={prevSlide} aria-label="Previous slide">‹</button>
            <button className='next-button' onClick={nextSlide} aria-label="Next slide">›</button> */}
        </div>
    );
}

export default Mobile_Productslider;
