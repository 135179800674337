import React, { useRef, useState, useCallback } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useWheel } from '@use-gesture/react';
import "./ProductSlider.scss";
import useSlideUpAnimation from '../../utils/slideUp';

function ProductSlider({ slides, mobileSlides }) {
    const [ref, inView] = useSlideUpAnimation();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    const titleRef = useRef(null);
    const descriptionRef = useRef(null);

    const handleMouseMove = (event, ref) => {
        const { clientX, clientY } = event;
        const { top, left, width, height } = ref.current.getBoundingClientRect();
        const x = (clientX - left - width / 2) / width * 50;  // Scale factor for rotation
        const y = (clientY - top - height / 2) / height * 50; // Scale factor for rotation

        ref.current.style.transform = `rotateX(${y}deg) rotateY(${x}deg)`;
    };

    const nextSlide = useCallback(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, [slides.length]);

    const prevSlide = useCallback(() => {
        setCurrentSlide((prevSlide) => prevSlide === 0 ? slides.length - 1 : prevSlide - 1);
    }, [slides.length]);

    const handlers = useSwipeable({
        onSwipedLeft: nextSlide,
        onSwipedRight: prevSlide,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    // Debounce function to limit the rate of slide changes
    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    };

    const handleWheel = debounce(({ direction: [x] }) => {
        if (x > 0) nextSlide();
        if (x < 0) prevSlide();
    }, 150); // Adjust the wait time to control the speed

    const bind = useWheel(handleWheel);

    return (
        <div
            className={`productSlider ${inView ? 'slideUp' : ''} ${isDragging ? 'dragging' : ''}`}
            ref={ref}
            {...handlers}
            {...bind()}
            onMouseDown={() => setIsDragging(true)}
            onMouseUp={() => setIsDragging(false)}
            onMouseLeave={() => setIsDragging(false)}
        >
            <div className='productslide'>
                <div className='product-image'>
                    <img src={slides[currentSlide].image} alt={slides[currentSlide].title} className='product-gif' />
                </div> 
                <div className='left-col'>
                    <div
                        className='product-title'
                        ref={titleRef}
                        onMouseMove={(e) => handleMouseMove(e, titleRef)}
                        onMouseOut={() => (titleRef.current.style.transform = 'rotateX(0) rotateY(0)')}
                    >
                        <span className='title'>
                            {slides[currentSlide].title}<br />
                            {slides[currentSlide].line1}<br />
                            {slides[currentSlide].line2 ? slides[currentSlide].line2 : null}
                        </span>
                    </div>
                    <div
                        className='product-description'
                        ref={descriptionRef}
                        onMouseMove={(e) => handleMouseMove(e, descriptionRef)}
                        onMouseOut={() => (descriptionRef.current.style.transform = 'rotateX(0) rotateY(0)')}
                    >
                        <h2 className='description'>{slides[currentSlide].description}</h2>
                    </div>
                </div>
            </div>

            {slides.length > 1 && (
                <div className='indicators'>
                    {slides.map((_, index) => (
                        <button
                            key={index}
                            className={`indicator-buttons ${currentSlide === index ? 'active' : ''}`}
                            onClick={() => setCurrentSlide(index)}
                            aria-label={`Go to slide ${index + 1}`}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

export default ProductSlider;
