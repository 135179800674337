import React from 'react';
import "./Terms_content.scss";

const TermsAndConditions = () => {
  return (
    <div className='terms'>
      <div className="terms-notes">
        <h1 className="title">Website Usage Terms and Conditions</h1>
        <h2 className="section-title">1. Acceptance of Terms</h2>
        <p className="section-content">
          By using the Pavanputra LLC Chatbot, accessible via WhatsApp, you agree to abide by these Terms and Conditions. If you disagree with any part of these terms, please refrain from using the chatbot. These terms apply to all users, including visitors and registered users.
        </p>
        <h2 className="section-title">2. Fitness Information Disclaimer</h2>
        <p className="section-content">
          The fitness-related responses provided by the Pavanputra LLC Chatbot are for informational purposes only. They do not constitute professional medical advice, diagnosis, or treatment. Always consult with a qualified healthcare provider before making any health or fitness decisions.
        </p>
        <h2 className="section-title">3. User Conduct and Content</h2>
        <p className="section-content">
          You are responsible for the content of your interactions with the Pavanputra LLC Chatbot. You shall not use the chatbot to engage in any harmful, abusive, or unlawful activities. You understand that any information shared with the chatbot is at your own risk and may be used for improving the service.
        </p>
        <h2 className="section-title">4. Accuracy and Reliability</h2>
        <p className="section-content">
          The fitness-related information provided by the Pavanputra LLC Chatbot is based on general knowledge and patterns in the data it has been trained on. Pavanputra LLC does not guarantee the accuracy, completeness, or reliability of the responses, and users should verify information independently.
        </p>
        <h2 className="section-title">5. User Privacy</h2>
        <p className="section-content">
          Pavanputra LLC is committed to user privacy. The chatbot operates within the guidelines of the Privacy Policy, which outlines how user data is collected, used, and protected.
        </p>
        <h2 className="section-title">6. Limitation of Liability</h2>
        <p className="section-content">
          Pavanputra LLC and its operators shall not be liable for any direct, indirect, incidental, consequential, or special damages arising from the use or misuse of the chatbot. Users acknowledge that the chatbot's responses are automated and should not replace expert advice.
        </p>
        <h2 className="section-title">7. Changes and Discontinuation</h2>
        <p className="section-content">
          Pavanputra LLC reserves the right to modify, suspend, or discontinue the chatbot service, or any part of it, at any time without prior notice. The terms may be updated from time to time, and continued usage after changes indicates acceptance of the revised terms.
        </p>
        <h2 className="section-title">8. Intellectual Property</h2>
        <p className="section-content">
          The Pavanputra LLC Chatbot, its name, design, and related content are protected by intellectual property laws and are the exclusive property of Pavanputra LLC.
        </p>
        <h2 className="section-title">9. Governing Law and Jurisdiction</h2>
        <p className="section-content">
          These terms are governed by the laws of the jurisdiction in which Pavanputra LLC is registered. Any disputes arising from the use of the chatbot shall be subject to the exclusive jurisdiction of the courts in the same jurisdiction.
        </p>
        <p className="closing">
          By using the Pavanputra LLC Chatbot, you confirm your understanding and agreement to these Terms and Conditions. If you do not agree, please refrain from using the chatbot.
        </p>
      </div>
    </div>
  )
}

export default TermsAndConditions;
