import React from 'react'
import "./Contactus.scss";
import Contactus_form from '../../components/contactus_form/Contactus_form';
const Contactus = () => {
  return (
    <div>
      <Contactus_form/>
    </div>
  )
}

export default Contactus
