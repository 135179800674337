import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const useSlideUpAnimation = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, // Triggers when 10% of the element is visible
  });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 150); // Duration of the slide-up animation in milliseconds

      return () => clearTimeout(timer);
    }
  }, [inView]);

  return [ref, isVisible];
};

export default useSlideUpAnimation;
