import React, { useRef } from 'react';
import "./Aboutus_slider.scss";
import AboutUs_Image from "../../assets/aboutus_image.svg";

const handleMouseMove = (event, ref) => {
  const { clientX, clientY } = event;
  const { top, left, width, height } = ref.current.getBoundingClientRect();
  const x = (clientX - left - width / 2) / width * 50;  // Scale factor for rotation
  const y = (clientY - top - height / 2) / height * 50; // Scale factor for rotation

  ref.current.style.transform = `rotateX(${y}deg) rotateY(${x}deg)`;
};

const Aboutus_slider = () => {
  const topDivRef = useRef(null);
  const bottomDivRef = useRef(null);

  return (
    <div className='aboutus_slider'>
      <div className='container'>
        <div className='slider_image'>
          <img className='main_image' src={AboutUs_Image} alt='aboutus' />
        </div>
        <div className='right_div'>
          <div
            className='right-top-div'
            ref={topDivRef}
            onMouseMove={(e) => handleMouseMove(e, topDivRef)}
            onMouseOut={() => (topDivRef.current.style.transform = 'rotateX(0) rotateY(0)')}
          >
            <h2 className='our_mission_title'>Our<br />Mission</h2>
          </div>
          <div
            className='right-bottom-div'
            ref={bottomDivRef}
            onMouseMove={(e) => handleMouseMove(e, bottomDivRef)}
            onMouseOut={() => (bottomDivRef.current.style.transform = 'rotateX(0) rotateY(0)')}
          >
            <span className='our_mission_description'>
              Our mission is to transform the way you approach health and fitness by providing state-of-the-art AI solutions that offer personalized guidance and support. We believe in making advanced technology accessible to everyone, ensuring you have the tools you need to achieve your wellness goals.
              <span className='highlight'>At Pavanputra, we are committed to fostering a healthier, happier community through innovation and dedication.</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aboutus_slider;
