import React from 'react';
import "./ProductDescription.scss";
import useSlideUpAnimation from '../../utils/slideUp';

function ProductDescription({ description }) {
    const [ref, inView] = useSlideUpAnimation();
    
    return (
        <div ref={ref} className={`productDescription ${inView ? 'slideUp' : ''}`}>
            <span className='description_text'>
                {description}
            </span>
        </div>
    );
}

export default ProductDescription;
