import React from 'react'
import "./Aboutus.scss"
import FeatureShowCase from '../../components/featureShowCase/FeatureShowCase';
import ProductSlider from '../../components/productSldier/ProductSlider';
import ProductDescription from '../../components/productDescription/ProductDescription';
import AboutUs_Image from "../../assets/aboutus_image.svg";
import Aboutus_Features from '../../components/aboutus_features/Aboutus_Features';
import Aboutus_slider from '../../components/aboutus_slider/Aboutus_slider';
const slides = [
    {
        id: 1,
        image: AboutUs_Image,
        title: 'Our',
        line1: "Mission",
        description: "Our mission is to transform the way you approach health and fitness by providing state-of-the-art AI solutions that offer personalized guidance and support. We believe in making advanced technology accessible to everyone, ensuring you have the tools you need to achieve your wellness goals. At Pavanputra, we are committed to fostering a healthier, happier community through innovation and dedication.",
    },
  ];

const descriptionText = "Our mission is to transform the way you approach health and fitness by providing state-of-the-art AI solutions that offer personalized guidance and support. We believe in making advanced technology accessible to everyone, ensuring you have the tools you need to achieve your wellness goals. At Pavanputra, we are committed to fostering a healthier, happier community through innovation and dedication.";

const Aboutus = () => {
  return (
    <div className='aboutus'>
        <ProductDescription description={descriptionText} />   
        <Aboutus_slider/>   
        {/* <ProductSlider slides={slides}/> */}
        <Aboutus_Features/>
        <FeatureShowCase/>
    </div>
  )
}

export default Aboutus
