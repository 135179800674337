import React from 'react'
import "./Version.scss"
import Version_content from '../../components/version_content/Version_content'
const Version = () => {
  return (
    <div>
      <Version_content/>
    </div>
  )
}

export default Version
