import React, { useState } from 'react';
import axios from 'axios';
import "./Contactus_form.scss";

const Contactus_form = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    console.log('Form data updated:', formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted with data:', formData);
    try {
      const response = await axios.post('https://pavanputra-backend-new.netlify.app/api/form/createform', formData);
      // console.log('Response from server:', response);
      setResponseMessage('Form details saved successfully');
      setIsError(false);
      setFormData({ name: '', email: '', message: '' }); // Reset form fields
    } catch (error) {
      // console.error('Error occurred while submitting the form:', error);
      setIsError(true);
      if (error.response) {
        // console.error('Server responded with:', error.response);
        setResponseMessage('An error occurred. Please try again later.');
      } else if (error.request) {
        // console.error('No response received from the server:', error.request);
        setResponseMessage('No response from server. Please try again later.');
      } else {
        // console.error('Error setting up the request:', error.message);
        setResponseMessage('Error submitting form. Please try again later.');
      }
    }
  };

  return (
    <div className='contactus_form'>
      <div className='contactus_form_container'>
        <div className='left_side'>
          <h1 className='title'>Love to hear<br/>From You.<br/>Get in Touch.</h1>
          <div className='para-div'>
            <span className='para'>We know you would all have queries we would love to <br/>
              hear it and come with innovative solutions to queries. 
            </span>
            <span className='para'>
              Follow us on our social media channels<br/>
              for updates, tips, and community support.
            </span>
            <span className='para2'>
              Reach out to us at <a href="mailto:info@pavan-putra.com" className='email'>info@pavan-putra.com</a> for any assistance.
            </span>
          </div>
        </div>
        <div className='mobile_div'>
          <h1 className='title3'>Love to hear From You.<br/>Get in Touch.</h1>
          <div className='mobile-para-div'>
            <span className='mobile-para'>We know you would all have queries we would love to 
              hear it and come with innovative solutions to queries. 
            </span>
            <span className='mobile-para'>
              Follow us on our social media channels
              for updates, tips, and community support.
            </span>
            <span className='mobile-para2'>
              Reach out to us at <a href="mailto:info@pavan-putra.com" className='email'>info@pavan-putra.com</a> for any assistance.
            </span>
          </div>
        </div>
        <div className='right_side'>
          <span className='title2'>Send us a message</span>
          <form className="contact-form" onSubmit={handleSubmit}>
            <input
              type="text"
              id="name"
              name="name"
              placeholder='name'
              className='input'
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              id="email"
              name="email"
              placeholder='email'
              className='input'
              value={formData.email}
              onChange={handleChange}
              required
            />
            <textarea
              id="message"
              name="message"
              rows="5"
              placeholder='message'
              className='input_message'
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <button type="submit" className="submit-button">Send Message</button>
          </form>
          {responseMessage && (
            <p className={`response-message ${isError ? 'error' : 'success'}`}>
              {responseMessage}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Contactus_form;
