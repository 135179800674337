import React from 'react';
import "./TrackerSlider.scss";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/mousewheel';

import useSlideUpAnimation from '../../utils/slideUp';

function TrackerSlider({ images }) {
  const [ref, inView] = useSlideUpAnimation();
  return (
    <div className={`trackerSlider ${inView ? 'slideUp' : ''}`} ref={ref}>
      <h2 className='tracker-text'>We have got Everything Tracked</h2>
      <div className='slider-container'>
        <Swiper
          modules={[Mousewheel]}
          spaceBetween={20} // Increase the space between slides
          slidesPerView={1.1}
          pagination={{ clickable: true }}
          mousewheel={{ sensitivity: 2.5 }} // Enable mousewheel scrolling
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <img className="sliderImage" src={image.src} alt={`Slide ${index}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default TrackerSlider;
