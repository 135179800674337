import React, { useRef, useState, useEffect } from 'react';
import './VideoPlayer.scss';
import VideoPauseButton from "../../assets/Play SVG.svg";
import VolumeIcon from "../../assets/Mute button SVG.svg";
import VolumeMute from "../../assets/Volume Mute.svg";
import VideoPlayButton from "../../assets/Pause button.svg";

function VideoPlayer() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [hasZoomed, setHasZoomed] = useState(true); // Initially zoomed in
  const [zoomedOutOnce, setZoomedOutOnce] = useState(false); // Track if zoomed out once

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    const handleScroll = () => {
      const videoElement = videoRef.current;
      const rect = videoElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (!zoomedOutOnce && rect.top <= windowHeight / 2 && rect.bottom >= windowHeight / 2) {
        setHasZoomed(false); // Zoom out to original state
        setZoomedOutOnce(true); // Ensure it happens only once
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [zoomedOutOnce]);

  return (
    <div className="videoPlayer">
      <h2 className='tryItOnYourOwn'>Try it on your own</h2>
      <div className={`videoContainer ${hasZoomed ? 'zoom-in' : 'zoom-original'}`}>
        <video ref={videoRef} src="https://pavanputraprodmedia.blob.core.windows.net/website/Video%20HQ%2090%20Seconds%20version%201080p.mp4" muted={isMuted} />
        <div className="video-overlay-components">
          <div className="play-pause-controller">
            <button onClick={togglePlayPause} className="play-pause-button">
              {isPlaying ? <img src={VideoPlayButton} alt="pause icon" className='icon'/> : <img src={VideoPauseButton} alt="play icon" className='icon'/>}
            </button>
          </div>
          <div className="video-text-and-volume-components">
            <div className='video-text-components'>
              <h3>
                Full Guide to <br />Pavanputra
              </h3>
              <span>There is nothing better than this</span>
            </div>
            <button onClick={toggleMute} className="volume-button">
              {isMuted ? <img className="volume-icon" src={VolumeMute} alt="volume icon"/> : <img className="volume-icon" src={VolumeIcon} alt="volume icon"/>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoPlayer;
