import React from 'react';
import "./Team_Component.scss";
import BIRENDER from "../../assets/birender.svg";
import MANIDEEP from "../../assets/MANIDEEP 111.svg";
import SATYAM from "../../assets/satyam.svg";
import KAMALESH from "../../assets/Kamalesh bro.svg";
import SONU from "../../assets/Sonu bro.svg";

const teamMembers = [
  {
    img: BIRENDER,
    name: "Birender Singh",
    role: "Founder and CEO"
  },
  {
    img: SATYAM,
    name: "Satyam Mishra",
    role: "Full-Stack Developer"
  },
  {
    img: SONU,
    name: "Sonu Jatav",
    role: "Marketing Head"
  },
  {
    img: MANIDEEP,
    name: "Manideep R Kummitha",
    role: "Front-End Developer"
  },
  
  {
    img: KAMALESH,
    name: "Kamlesh Thakur",
    role: "Social Media Manager"
  },
 
];

const Team_Component = () => {
  return (
    <div className='team_component'>
      <div className='container'>
        {teamMembers.map((member, index) => (
          <div className='team_member' key={index}>
            <img src={member.img} alt={`${member.name}`} className='team_member_image'/>
            <div className='name_and_role'>
              <h2 className='member_name'>{member.name}</h2>
              <h2 className='member_role'>{member.role}</h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Team_Component;
