import React, { useState, useCallback } from 'react';
import { useWheel } from '@use-gesture/react';
import './Testimonials.scss';
import Profile1 from "../../assets/testimonials_profile1.svg";
import Profile2 from "../../assets/testimonials_profile2.svg";
import Profile3 from "../../assets/testimonials_profile3.svg";
import Profile4 from "../../assets/testimonials_profile4.svg";
import Profile5 from "../../assets/testimonials_profile5.svg";
import Profile6 from "../../assets/testimonials_profile6.svg";
import useSlideUpAnimation from '../../utils/slideUp';

const testimonialsData = [
  {
    id: 1,
    text: "I've had a fantastic experience using this product. The features are comprehensive, and the support team is always ready to help. Highly recommend it!",
    profileImg: Profile1,
  },
  {
    id: 2,
    text: "This service has significantly improved my daily workflow. The user interface is intuitive, and I love the flexibility it offers. A game changer!",
    profileImg: Profile2,
  },
  {
    id: 3,
    text: "The best investment I made this year! It's user-friendly and packed with useful features. The customer service is top-notch, responding promptly and effectively.",
    profileImg: Profile3,
  },
  {
    id: 4,
    text: "Absolutely love this product! It has streamlined many of my tasks and saved me a lot of time. The team behind it is very responsive and helpful.",
    profileImg: Profile4,
  },
  {
    id: 5,
    text: "Using this service has been a game-changer for my daily operations. The efficiency and reliability it provides are unmatched. A must-have for professionals!",
    profileImg: Profile5,
  },
  {
    id: 6,
    text: "I've tried many similar products, but this one stands out for its ease of use and powerful features. The support team is excellent and very responsive.",
    profileImg: Profile6,
  },
];

function Testimonials() {
  const [ref, inView] = useSlideUpAnimation();

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonials = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 2) % testimonialsData.length);
  }, []);

  const prevTestimonials = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonialsData.length - 2 : prevIndex - 2
    );
  }, []);

  const handleIndicatorClick = (index) => {
    setCurrentIndex(index * 2);
  };

  // Debounce function to limit the rate of slide changes
  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  const handleWheel = debounce(({ direction: [x] }) => {
    if (x > 0) nextTestimonials();
    if (x < 0) prevTestimonials();
  }, 150); // Adjust the wait time to control the speed

  const bind = useWheel(handleWheel);

  const currentTestimonials = [
    testimonialsData[currentIndex],
    testimonialsData[(currentIndex + 1) % testimonialsData.length],
  ];

  return (
    <div
      className={`testimonials ${inView ? 'slideUp' : ''}`} 
      ref={ref}
      {...bind()}
    >
      <div className='testimonials-container'>
        <h2 className='title'>What do people say</h2>
        <div className='testimonials-card'>
          {currentTestimonials.map((testimonial) => (
            <div className='card' key={testimonial.id}>
              <img className='profile-img' src={testimonial.profileImg} alt='profile' />
              <p className='testimonial-text'>{testimonial.text}</p>
            </div>
          ))}
        </div>
        <div className='indicators-button'>
          {[...Array(3)].map((_, index) => (
            <button
              key={index}
              className={`indicator-button ${currentIndex / 2 === index ? 'active' : ''}`}
              onClick={() => handleIndicatorClick(index)}
            ></button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
