import React from 'react'
// import HeroSection from '../../components/heroSection/HeroSection';
import ProductDescription from '../../components/productDescription/ProductDescription';
import ProductSlider from '../../components/productSldier/ProductSlider';
import TrackerSlider from '../../components/trackerSlider/TrackerSlider';
import VideoPlayer from '../../components/videoPlayer/VideoPlayer';
import FeatureShowCase from '../../components/featureShowCase/FeatureShowCase';
import Testimonials from '../../components/testimonials/Testimonials';
import SecureTransaction from '../../components/secureTransaction/SecureTransaction';
import Mobile_Productslider from '../../components/mobileversionproductslider/Mobile_Productslider';
import "./home.scss";

import IMAGE1 from "../../assets/TRACKER IMAGE 1.svg";
import IMAGE2 from "../../assets/TRACKER IMAGE 2.svg";
import IMAGE3 from "../../assets/TRACKER IMAGE 3.svg";
// import Footer from '../../components/footer/Footer';

import GIF1 from "../../assets/GIF 1.gif";
import GIF2 from "../../assets/GIF 2.gif";
import GIF3 from "../../assets/GIF 3.gif";

// import Image_Slider from '../../components/image_slider/Image_Slider';
const images = [
  { src: IMAGE1 },
  { src: IMAGE2 },
  { src: IMAGE3 }
]

const cards = [
  {
    id: 1,
    title: 'House in the woods',
    description: 'A serene and tranquil retreat, this house in the woods offers a peaceful escape from the hustle and bustle of city life.',
    className: 'col-span-2',
  },
  {
    id: 2,
    title: 'House above the clouds',
    description: 'Perched high above the world, this house offers breathtaking views and a unique living experience. It\'s a place where the sky meets home, and tranquility is a way of life.',
    className: 'col-span-1',
  },
  {
    id: 3,
    title: 'Greens all over',
    description: 'A house surrounded by greenery and nature\'s beauty. It\'s the perfect place to relax, unwind, and enjoy life.',
    className: 'col-span-1',
  },
  {
    id: 4,
    title: 'Rivers are serene',
    description: 'A house by the river is a place of peace and tranquility. It\'s the perfect place to relax, unwind, and enjoy life.',
    className: 'col-span-2',
  },
];


const slides = [
  {
      id: 1,
      image: GIF1,
      title: 'Fitness AI Chatbot ',
      line1: "Available 24x7",
      description: "Exclusive text chat for subscribers is now available, featuring the user-friendly model GPT-4. Get free text-based gym and fitness advice and engage in text-only fitness chats. Activate your chat feature with your subscription, and enjoy a personalized experience that remembers your interactions, enhancing every conversation with tailored guidance.",
  },
  {
      id: 2,
      image: GIF2,
      title: 'Calorie Analyzer-',
      line1: "Click - Upload -",
      line2: "Repeat",
      description: "Experience photo calorie counting for your meals and engage in 24/7 text conversations about gym and fitness. With VIP chat access for subscribers and responses powered by GPT-3, you're guaranteed top-notch interaction. Join the fitness conversation today and unlock advanced calorie counting features to elevate your health journey. This service ensures you stay informed and supported.",
  },
  {
      id: 3,
      image: GIF3,
      title: 'Form Check?',
      line1: "Upload it - Receive",
      line2: "Recommendations",
      description: "Utilize video form check with advanced ML models and snap meal photos for accurate calorie counting. We're upgrading to offer the ultimate chatbot experience, enhancing your ability to engage in text conversations about gym and fitness. Access specialized, text-based fitness chats with your subscription, designed to optimize your fitness journey and keep you motivated with state-of-the-art technology.",
  }
];

const descriptionText = "Pavanputra harnesses cutting-edge AI to transform your health and diet. Our tools analyze your eating habits, providing tailored nutritional insights. Join us to take the first step towards a smarter, healthier you.";

function Home() {
  return (
    <div className='home'>
      {/* <HeroSection/> */}
      <ProductDescription description={descriptionText} />  
       <ProductSlider slides={slides}/>
       <Mobile_Productslider/>
      <TrackerSlider images={images}/>
      <VideoPlayer/>
      <FeatureShowCase  />
      <Testimonials/>
      <SecureTransaction/>
      {/* <Image_Slider/> */}
      {/* <Footer/> */}
    </div>
  )
}

export default Home
