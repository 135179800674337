import React from 'react';
import "./Aboutus_Features.scss";
import IMAGE1 from "../../assets/vedio workout analysis.svg";
import IMAGE2 from "../../assets/meal analysis.svg"
const Aboutus_Features = () => {
  return (
    <div className='aboutus_features'>
      <div className='container'>
        <h1 className='innovative_analysis'>Innovative Analysis</h1>
        <div className='cards'>
        <div className='aboutus_card'>
          <img className='aboutus_image' src={IMAGE1} alt="image"/>
          <div className='overlay_content'>
            <h2 className='analysis_title'>Meal<br/> Photo Analysis</h2>
            <span className='analysis_title_description'>Capture and analyze your meals for accurate calorie counting and nutritional insights.</span>
          </div>
        </div>
        <div className='aboutus_card'>
          <img className='aboutus_image' src={IMAGE2} alt="image"/>
          <div className='overlay_content'>
            <h2 className='analysis_title'>Video<br/> Workout Analysis</h2>
            <span className='analysis_title_description'>Upload your workout videos to receive AI-powered feedback and recommendations for optimal results.</span>
          </div>
        </div>

        </div>
      </div>
    </div>
  )
}

export default Aboutus_Features
