import React from 'react';
import "./FeatureShowCase.scss";
import useSlideUpAnimation from '../../utils/slideUp';

function FeatureShowCase() {
  const [ref, inView] = useSlideUpAnimation();

  return (
    <div className={`featureShowCase ${inView ? 'slideUp' : ''}`} ref={ref}>
      <div className='features_container'>
        <div className='left-column-features'>
          <div className='point-one point'>
            <h2 className='title'>Personalized <br/> Workout Plan</h2>
            <span className='description'>Get a workout plan tailored specifically to your needs and goals, ensuring optimal results and continuous progress.</span>
          </div>

          <div className='point-two point'>
            <h2 className='title'>Adaptive Thinking</h2>
            <span className='description'>Get a workout plan tailored specifically to your needs and goals, ensuring optimal results and continuous progress.</span>
          </div>
        </div>

        <div className='right-column-features'>
          <div className='point-three point'>
            <h2 className='title'>Progress Tracker</h2>
            <span className='description'>Get a workout plan tailored specifically to your needs and goals, ensuring optimal results and continuous progress.</span>
          </div>

          <div className='point-four point'>
            <h2 className='title'>Motivational <br/>Support</h2>
            <div className='subtitle'>
              <p className='time'>24 X 7</p>
              <p className='subtext'>Build your dream body now!</p>
            </div>
            <span className='description'>Get a workout plan tailored specifically to your needs and goals, ensuring optimal results and continuous progress.</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureShowCase;
