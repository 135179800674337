// src/components/heroSection/HeroSection.js
import React, { useState } from 'react';
import './HeroSection.scss';
import aboutUsImage from '../../assets/about us home page.svg';
import versionImage from '../../assets/version home page.svg';
import teamImage from '../../assets/team home page.svg';
import contactUsImage from '../../assets/contact us home page.svg';
import { Link, useLocation } from 'react-router-dom';
import LOGO from "../../assets/logo.svg";

function HeroSection() {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [darkBackground, setDarkBackground] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    setDarkBackground(!darkBackground);
  };

  const handleLinkClick = () => {
    setShowMenu(false);
    setDarkBackground(false);
    window.scrollTo(0, 0);
  };

  const getPage = () => {
    const path = location.pathname.toLowerCase();
    if (path.includes('aboutus')) return 'aboutus';
    if (path.includes('version')) return 'version';
    if (path.includes('team')) return 'team';
    if (path.includes('contactus')) return 'contactus';
    if (path.includes('termsandconditions')) return 'termsandconditions';
    if (path.includes('privacyandpolicy')) return 'privacyandpolicy';
    return '';
  };

  const page = getPage();

  const renderContent = () => {
    switch (page) {
      case 'aboutus':
        return <h1>About Us</h1>;
      case 'version':
        return <h1>Version</h1>;
      case 'team':
        return <h1>Team</h1>;
      case 'contactus':
        return <h1>Contact Us</h1>;
      case 'termsandconditions':
        return <h1>Terms and Conditions</h1>;
      case 'privacyandpolicy':
        return <h1>Privacy and Policy</h1>;
      default:
        return <h1>Get Started</h1>;
    }
  };

  const renderButton = () => {
    if (['aboutus', 'version', 'team', 'contactus', 'termsandconditions', 'privacyandpolicy'].includes(page)) {
      return null;
    }
    return (
      <a className="getstartedforfree" href="https://dashboard.pavan-putra.com/" target="_blank" rel="noopener noreferrer">Sign Up Now for Free!</a>
    );
  };

  const renderMedia = () => {
    switch (page) {
      case 'aboutus':
        return <img src={aboutUsImage} alt="About Us" className="hero-image" />;
      case 'version':
        return <img src={versionImage} alt="Version" className="hero-image" />;
      case 'team':
        return <img src={teamImage} alt="Team" className="hero-image" />;
      case 'contactus':
        return <img src={contactUsImage} alt="Contact Us" className="hero-image" />;
      default:
        return (
          <video autoPlay loop muted>
            <source src="https://pavanputraprodmedia.blob.core.windows.net/website/PavanPutra%20Main%20Video.mp4" type="video/mp4" />
          </video>
        );
    }
  };

  return (
    <div className="heroSection">
      {darkBackground && <div className="dark-background-overlay" />}
      <div className={`video-wrapper ${darkBackground ? 'dark-background' : ''}`}>
        {renderMedia()}
        <div className='overlay-content'>
          <div className='top-div'>
            <Link to="/">
              <img src={LOGO} alt="logo image" className='logo_image' />
            </Link>
            <div className='menu-content-mobile-version'>
              <button className='menu-button' onClick={toggleMenu}>Menu</button>
            </div>
          </div>
          <div className='bottom-div'>
            <div className='left-container'>
              <div className='value-proposition'>
                {renderContent()}
              </div>
              {renderButton()}
            </div>
          </div>
        </div>
      </div>
      <div className={`menu-content ${showMenu ? 'show' : ''}`}>
        <ul>
          <li><Link to="/aboutus" className='link' onClick={handleLinkClick}>About Us</Link></li>
          <li><Link to="/version" className='link' onClick={handleLinkClick}>Version</Link></li>
          <li><Link to="/team" className='link' onClick={handleLinkClick}>Team</Link></li>
          <li><Link to="/contactus" className='link' onClick={handleLinkClick}>Contact Us</Link></li>
          <li><Link to="https://dashboard.pavan-putra.com/" className='link' onClick={handleLinkClick}>Chat Now for Free!</Link></li>
          {showMenu && <li><button className='close-button' onClick={toggleMenu}>Close</button></li>}
        </ul>
      </div>
    </div>
  );
}

export default HeroSection;
