import React from 'react';
import "./Version_content.scss";

const Version_content = () => {
  return (
    <div className='version'>
      <div className="version-notes">
            <h1 className="title">Version v0.5 Release Notes</h1>
            <p className="intro">
                We are excited to announce the release of Pavanputra version v0.5, bringing a host of new features and improvements designed to enhance your health and fitness journey. Here’s what’s new:
            </p>
            <h2 className="section-title">10 Free Messages for All Users</h2>
            <p className="section-content">
                In this update, we are introducing a new feature that allows users to send and receive 10 free messages without any payment. This initiative aims to give you a taste of our premium chatbot services, helping you make informed decisions about your fitness journey without any initial cost.
            </p>
            <h2 className="section-title">Enhanced Dashboard with Comprehensive Tracking</h2>
            <p className="section-content">
                Our revamped dashboard is now more powerful and user-friendly, providing a holistic view of your health data. Here are the key enhancements:
                <ul>
                <li><strong>Chatbot Interaction Tracking:</strong> Keep track of all your interactions with our fitness AI chatbot. Review past conversations and get insights into your fitness queries and advice received.</li>
                <li><strong>Calorie and Diet Plan Tracking:</strong> Easily monitor your calorie intake and diet plans. The dashboard now offers a detailed breakdown of what you’re eating, helping you stay on top of your nutritional goals.</li>
                </ul>
            </p>
            <h2 className="section-title">Real-time Data Updates</h2>
            <p className="section-content">
                We have significantly improved the real-time capabilities of our dashboard:
                <ul>
                <li><strong>Instant Updates:</strong> See your photo and video workout analysis, including scores and calorie calculations, reflected on your dashboard almost instantly. This ensures you always have the most up-to-date information to make informed decisions about your health and fitness.</li>
                </ul>
            </p>
            <h2 className="section-title">Enhanced Photo and Video Workout Analysis</h2>
            <p className="section-content">
                Our AI-powered analysis tools have received significant upgrades:
                <ul>
                <li><strong>Accurate Calorie Calculations:</strong> Our advanced algorithms now provide even more precise calorie calculations for your meals and workouts.</li>
                <li><strong>Detailed Feedback and Scores:</strong> Upload your workout videos to receive comprehensive feedback and scores. Our improved AI models analyze your form and offer recommendations to help you maximize your results.</li>
                </ul>
            </p>
            <p className="closing">
                These enhancements aim to provide you with a seamless and integrated experience, making it easier to track your progress and stay motivated. We are committed to continually improving Pavanputra to support your health and fitness journey.
                <br />
                Thank you for being a part of the Pavanputra community. We look forward to helping you achieve your wellness goals with these exciting new features.
            </p>
        </div>
    </div>
  )
}

export default Version_content
