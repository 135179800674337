import React from 'react';
import googlepay from "../../assets/gpay.svg";
import applepay from "../../assets/apple pay.svg";
import visa from "../../assets/visa.svg";
import mastercard from "../../assets/mastercard.svg";
import americanexpress from "../../assets/american express.svg";
import stripe from "../../assets/Stripe.svg";
import useSlideUpAnimation from '../../utils/slideUp';
import "./SecureTransaction.scss";

const SecureTransaction = () => {
  const [ref, inView] = useSlideUpAnimation();

  return (
    <div className={`secure-transaction ${inView ? 'slideUp' : ''}`} ref={ref}>
      <div className='secure-transaction-container'>
        <div className='card1'>
          <p className='secure-text'>Secure <br/> Transaction</p>
        </div>
        <div className='card2'>
          <p className='payment-text'>Payments enabled through</p>
          <img className='stripe-img' src={stripe} alt="stripe"/>
          <div className='transactions-logo'>
            <img className='logo-img' src={googlepay} alt="googlepay"/>
            <img className='logo-img' src={applepay} alt="applepay"/>
            <img className='logo-img' src={americanexpress} alt="americanexpress"/>
            <img className='logo-img' src={mastercard} alt="mastercard"/>
            <img className='logo-img' src={visa} alt="visa"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecureTransaction;
