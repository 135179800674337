import React from 'react';
import "./Privacy_Content.scss";

const PrivacyPolicy = () => {
  return (
    <div className='privacy'>
      <div className="privacy-notes">
        <h1 className="title">Privacy Policy</h1>
        <p className="intro">
          At Pavanputra LLC, we are committed to safeguarding your privacy. This Privacy Policy outlines how we collect, use, and protect your personal data when you use our Chatbot accessible via WhatsApp.
        </p>
        <h2 className="section-title">1. Information Collection</h2>
        <p className="section-content">
          We collect information you provide while interacting with our Chatbot. This may include personal details, fitness-related queries, and other information shared during conversations.
        </p>
        <h2 className="section-title">2. Usage of Information</h2>
        <p className="section-content">
          The information collected is used to improve our Chatbot's responses and provide personalized fitness-related insights. Your data is securely stored and analyzed to enhance user experience.
        </p>
        <h2 className="section-title">3. Information Sharing</h2>
        <p className="section-content">
          We do not share your personal information with third parties for marketing purposes. However, we may share aggregated and anonymized data for research and analytics.
        </p>
        <h2 className="section-title">4. Security</h2>
        <p className="section-content">
          We implement technical and organizational measures to protect your data from unauthorized access or disclosure. Your privacy and data security are of utmost importance to us.
        </p>
        <h2 className="section-title">5. Changes to Policy</h2>
        <p className="section-content">
          Pavanputra LLC reserves the right to update this Privacy Policy. Any changes will be posted on this page. Continued use of our Chatbot after changes implies your acceptance of the revised policy.
        </p>
        <h2 className="section-title">6. Contact Us</h2>
        <p className="section-content">
          If you have any questions or concerns regarding this Privacy Policy, please contact us.
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicy;
