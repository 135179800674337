import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./Footer.scss";
import logo from "../../assets/logo.svg";
import facebook from "../../assets/facebook.svg";
import twitter from "../../assets/twitter.svg";
import instagram from "../../assets/instagram.svg";
import youtube from "../../assets/youtube.svg";
import useSlideUpAnimation from '../../utils/slideUp';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
  const [ref, inView] = useSlideUpAnimation();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleSubscribe = async () => {
    if (!email) {
      setResponseMessage('Email is required');
      return;
    }
    try {
      // console.log('Sending subscription request with email:', email);
      const response = await axios.post('https://pavanputra-backend-new.netlify.app/api/subscribers/subscribe', { email });
      // console.log('Response from server:', response);
      setResponseMessage('Subscription successful');
      setSubscriptionSuccess(true);
      setEmail(''); // Clear the input field
    } catch (error) {
      // console.error('Error occurred while subscribing:', error);
      setSubscriptionSuccess(false);
      if (error.response) {
        // console.error('Server responded with:', error.response);
        setResponseMessage(error.response.data);
      } else if (error.request) {
        // console.error('No response received from the server:', error.request);
        setResponseMessage('No response from server. Please try again later.');
      } else {
        // console.error('Error setting up the request:', error.message);
        setResponseMessage('Error submitting subscription. Please try again later.');
      }
    }
  };

  return (
    <div className={`footer ${inView ? 'slideUp' : ''}`} ref={ref}>
      <div className='container'>
        <div className='footer-top'>
          <div className='footer-top-left'>
            <div className='footer-top-left-up'>
              <img className='pavanputra-logo' src={logo} alt='logo'/>
              <div className='footer-left-text'>
                <p className='footer-main-text'>
                  Pavanputra leverages advanced artificial intelligence to enhance your health and wellness journey. By offering innovative tools like meal photo analysis and customized fitness chats, we empower you to achieve and maintain optimal health. Trust Pavanputra to be your partner in navigating the complexities of nutrition and fitness with ease and precision.
                </p>
              </div>
            </div>
            <div className='footer-top-left-down'>
              <a href={"https://www.instagram.com/thepavanputra/"} target="_blank" rel="noopener noreferrer">
                <img src={instagram} alt="instagram" className='social-media-icons'/>
              </a>
              <a href={"https://www.facebook.com/people/ThePavanputra/100087514974261/"} target="_blank" rel="noopener noreferrer">
                <img src={facebook} alt="facebook" className='social-media-icons'/>
              </a>
              <a href={"https://www.youtube.com/@The_Pavanputra"} target="_blank" rel="noopener noreferrer">
                <img src={youtube} alt='youtube' className='social-media-icons'/>
              </a>
            </div>
          </div>
          <div className='footer-top-center'>
            <hr className='line-break-1'/>
            <h3 className='footer-top-center-text'>Site Map</h3>
            <ul>
              <li><Link to="/aboutus" className='link'>About Us</Link></li>
              <li><Link to="/version" className='link'>Version</Link></li>
              <li><Link to="/team" className='link'>Team</Link></li>
              <li><Link to="https://careers.pavan-putra.com/" className='link'>Careers</Link></li>
              <li><Link to="/contactus" className='link'>Contact Us</Link></li>
              <li><Link to="https://dashboard.pavan-putra.com/" className='link'>Get Started for Free!</Link></li>
            </ul>
            <hr className='line-break-2'/>
          </div>
          <div className='footer-top-right'>
            <h3 className='subscribe-text'>Subscribe</h3>
            <div className='sub-div'>
              <div className='subscribe-input-div'>
                <input
                  type='email'
                  className='subscribe-input'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder='Enter your email address'
                />
                <button className='subscribe-button' onClick={handleSubscribe}>Subscribe</button>
              </div>
              <div className='response-message-div'>
                {responseMessage && (
                  <p className={`response-message ${subscriptionSuccess ? 'success' : 'error'}`}>
                    {responseMessage}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr className='line-break'/>
        <div className='footer-bottom'>
          <div className='footer-bottom-left'>
            <Link to="/privacyandpolicy" className='footer-bottom-text'>Pavanputra Privacy Policy</Link>
            <Link to="/termsandconditions" className='footer-bottom-text'>Terms & Conditions</Link>
          </div>
          <div className='footer-bottom-right'>
            <span className='footer-bottom-text'>Copyright © 2023 Pavanputra All Rights Reserved.</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;
