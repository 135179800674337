import React from 'react';
import "./Privacy.scss";
import Privacy_Content from '../../components/privacy_content/Privacy_Content';
const Privacy = () => {
  return (
    <div>
      <Privacy_Content/>
    </div>
  )
}

export default Privacy
