import React from 'react';
import "./Terms.scss";
import Terms_content from '../../components/terms_content/Terms_content';
const Terms = () => {
  return (
    <div>
      <Terms_content/>
    </div>
  )
}

export default Terms
